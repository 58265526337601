import axios from 'axios'
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import { Button, Spinner } from '@nike/eds'
import {oktaAuth} from '../../shared/Okta'
import {ReportType} from '../../model/ReportType'
import {downloadConfig} from './DownloadConfig'
import {returnsOverviewFilterStore} from '../returns-overview/ReturnsOverviewFilterStore'
import {PlantCodeUpdateUtil} from '../../shared/component/PlantCodeUpdateUtil'

interface ButtonProp {
    onClick: (string) => boolean,
    reportType: any,
    isDisabled: boolean,
    setIsDisabled: Dispatch<SetStateAction<boolean>>,
    initErrorPopover: () => void,
}

export const DownloadButton = ({ onClick, reportType, isDisabled, setIsDisabled, initErrorPopover } : ButtonProp) => {
    const DEFAULT_FILE_NAME = 'rsos_report.xlsx'
    const RETURNS_EXPORT_SERVICE_URL = process.env.REACT_APP_RETURNS_EXPORT_SERVICE_URL

    const loadingButton = downloadConfig.getLoadingButton()
    const [isLoading, setIsLoading] = useState(loadingButton === reportType.reportType)

    const defaultPlantCodes = PlantCodeUpdateUtil().plantCodes

    useEffect(() => {
        if (!loadingButton && isLoading) {
            setIsLoading(false)
        }
    }, [loadingButton, reportType, isLoading]);

    const handleDownload = (reportType) => {
        const shouldProceedDownloading = onClick(reportType)

        if (shouldProceedDownloading) {
            downloadConfig.updateLoadingButton(reportType)
            setIsLoading(true)
            const filterData = returnsOverviewFilterStore.getFinanceFilterStoredData()
            const requestBody = prepareRequestBody(filterData, reportType)
            const token = oktaAuth.getAccessToken()

            axios.post(RETURNS_EXPORT_SERVICE_URL, requestBody, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type' : 'application/json',
                },
                responseType: 'blob',
            })
            .then(response => {
                const fileName = extractFileNameFromResponse(response)
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
                link.remove()
                clearLoadingState()
            })
            .catch(handleError)
        }
    }

    const extractFileNameFromResponse = response => {
        const contentDisposition = response.headers['content-disposition']
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+)"/)
            if (match && match[1]) {
                return match[1]
            }
        }
        return DEFAULT_FILE_NAME
    }

    const handleError = error => {
        console.error(error)
        clearLoadingState()
        initErrorPopover()
    }

    const clearLoadingState = () => {
        downloadConfig.clearLoadingButton()
        setIsLoading(false)
        setIsDisabled(false)
    }

    const prepareRequestBody = (filterData, reportType) => {
        return  {
            reportType: reportType || ReportType.RSO,
            receivedDateFrom: filterData.receivedDateTimeFrom || null,
            receivedDateTo: filterData.receivedDateTimeTo || null,
            soldToCodes: filterData.soldToCustomers || [],
            shipToCodes: filterData.shipToCustomers || [],
            rsoNumbers: filterData.returnSalesOrderNumbers || [],
            statuses: filterData.status || [],
            plantCodes: filterData.plantCodes || defaultPlantCodes,
        }
    }

    return (
        <Button
            size="small"
            style={{ marginTop: 'var(--eds-space-8)', marginRight: 'var(--eds-space-8)' }}
            onClick={() => handleDownload(reportType.reportType)}
            disabled={isDisabled}
            key={reportType.reportType}
        >
            {isLoading ? <Spinner /> : reportType.title}
        </Button>
    )
}